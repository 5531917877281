import { memo } from "react";
import { useSettingsModalState } from "lib/useSettingsModalState";
import { SettingsModal } from "components/SettingsModal/SettingsModal";
import { useCallback, useEffect, useState } from "react";

import "./SettingsButton.scss";

import SettingsIcon from "img/settings.svg?react";
import settingsIcon from "img/ic_settings_3.svg";
// import { IoMdSettings } from "react-icons/io";

type Props = {
  openSettings: () => void;
};

export const SettingsButton = memo(function SettingsButton({openSettings}: Props) {

  return (
    <div className="SettingsButton" onClick={openSettings}>
      <img className="network-option-img" src={settingsIcon} alt="" />
    </div>
  );
});
